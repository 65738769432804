import React, { useEffect, useState } from "react";
import Card from "../../shared/Card";
import Progress from "../../shared/Progress";
import * as IMAGES from "../../shared/images";
import { Link } from "react-router-dom";
import { useGlobal } from "../../services/Context";
import { useFlutterwave } from 'flutterwave-react-v3';
import Modal from "../../shared/Modal";
import { Course } from "../../types/course";

const Courses = () => {
    const [isActive, setisActive] = useState(0)
    const { dashboardData, resumeCourse, resetAll, completeInstallmentCourseEnrollment, isLoadingBtn, showmodal } = useGlobal();
    useEffect(() => {
        resetAll()
    })

    const resume = (id: string) => {
        resumeCourse(id)
    }
    const addDays = (date: string, days: string) => {
        var result = new Date(date);
        result.setDate(result.getDate() + Number(days));
        return result;
    }
    return (
        <div>
            {/* <div>
                <ClassroomHeader />
            </div>
            <div className="main-body">
                {
                    toggleSidebar &&
                    <div id="sidebar">
                        <LandngSidebar />
                    </div>
                }
                <div id="main">
                    <main> */}
            <div className="landing" style={{ padding: "1em" }}>
                <div className="technologies-categories--technology-category accordian--tab">
                    <div className="accordian--tab__btn" onClick={() => setisActive(isActive === 0 ? 3 : 0)}>
                        <p>Free Courses</p>
                    </div>
                    {
                        isActive === 0 && (
                            <div className="all-courses-con">
                                {
                                    dashboardData?.data?.free?.map((item: Course, idx: number) => (
                                        <Card key={idx}>
                                            <div className="all-courses">
                                                <div className="course">
                                                    <div>
                                                        <img src={item?.courseId?.featuredImage} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h4>{item?.courseId?.title}</h4>
                                                        <p>{item?.courseId?.level}</p>
                                                        <div className="progress">
                                                            <div className="full-progress-bar">
                                                                <div style={{ position: 'absolute', top: '-50%' }}>
                                                                    <Progress progress={item?.courseId?.progress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link to={`/free/dashboard/${item?.courseId?._id}/modules/1`}>
                                                            <button onClick={() => resume(item?.courseId?._id)}>Resume</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </Card>
                                    ))
                                }{
                                    (dashboardData?.data?.free?.length < 1 ||
                                        !dashboardData?.data?.free?.length ||
                                        dashboardData?.data?.free?.length === null ||
                                        dashboardData?.data?.free?.length === undefined) && (
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={IMAGES.Nodata} alt="empty" width="300px" />
                                        </div>
                                    )
                                }


                            </div>
                        )
                    }
                </div>
                <div className="technologies-categories--technology-category accordian--tab">
                    <div className="accordian--tab__btn" onClick={() => setisActive(isActive === 1 ? 3 : 1)}>
                        <p>Crash Courses</p>
                    </div>
                    {
                        isActive === 1 && (
                            <div className="all-courses-con">

                                {
                                    dashboardData?.data?.crash?.map((item: Course, idx: number) => (
                                        <Card key={idx}>
                                            <div className="all-courses">
                                                <div className="course">
                                                    <div>
                                                        <img src={item?.courseId?.featuredImage} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h4>{item?.courseId?.title}</h4>
                                                        <p>{item?.courseId?.level}</p>
                                                        {/* <small>0 out of 20 lessons</small> */}
                                                        <div className="progress">
                                                            <div className="full-progress-bar">
                                                                <div style={{ position: 'absolute', top: '-50%' }}>
                                                                    <Progress progress={item?.courseId?.progress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link to={`/crash/dashboard/${item?.courseId?._id}/modules/1`}>
                                                            <button onClick={() => resume(item?.courseId?._id)}>Resume</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </Card>
                                    ))
                                }

                                {
                                    (dashboardData?.data?.crash?.length < 1 ||
                                        !dashboardData?.data?.crash?.length ||
                                        dashboardData?.data?.crash?.length === null ||
                                        dashboardData?.data?.crash?.length === undefined)
                                    && (
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={IMAGES.Nodata} alt="empty" width="300px" />
                                        </div>
                                    )
                                }


                            </div>
                        )
                    }
                </div>
                <div className="technologies-categories--technology-category accordian--tab">
                    <div className="accordian--tab__btn" onClick={() => setisActive(isActive === 2 ? 3 : 2)}>
                        <p>Expert Led Courses</p>
                    </div>
                    {
                        isActive === 2 && (
                            <div className="all-courses-con">
                                {
                                    dashboardData?.data?.expert?.map((item: Course, idx: number) => (
                                        <Card key={idx}>
                                            <div className="all-courses">
                                                <div className="course">
                                                    <div>
                                                        <img src={item?.courseId?.featuredImage} alt="img" />
                                                    </div>
                                                    <div>
                                                        <h4>{item?.courseId?.title}</h4>
                                                        <p>{item?.courseId?.level}</p>
                                                        {/* <small>0 out of 20 lessons</small> */}
                                                        <div className="progress">
                                                            <div className="full-progress-bar">
                                                                <div style={{ position: 'absolute', top: '-50%' }}>
                                                                    <Progress progress={item?.courseId?.progress} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link to={`/expert-led/dashboard/${item?.courseId?._id}/main`}>
                                                            <button onClick={() => resume(item?.courseId?._id)}>Resume</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <hr />
                                                {
                                                    item?.isInstallmental && item?.isPaid === false &&
                                                    <>
                                                        <a onClick={() => completeInstallmentCourseEnrollment(item?.courseId?._id)}> <p style={{ float: 'right' }}>{isLoadingBtn ? "Loading..." : "Pay Now"}</p></a>
                                                        <p>Pending payment with deadline of {addDays(item?.date, "90").toDateString()}</p>

                                                    </>
                                                }
                                            </div>
                                        </Card>
                                    ))
                                }{
                                    (dashboardData?.data?.expert?.length < 1 ||
                                        !dashboardData?.data?.expert?.length ||
                                        dashboardData?.data?.expert?.length === null ||
                                        dashboardData?.data?.expert?.length === undefined) && (
                                        <div style={{ textAlign: 'center' }}>
                                            <img src={IMAGES.Nodata} alt="empty" width="300px" />
                                        </div>
                                    )
                                }


                            </div>
                        )
                    }
                </div>
                {/* </div>
                    </main>
                </div> */}
            </div>
            {/* { isLoading && <Loaders />} */}
            {showmodal.id === 100 && <PaymentModal />}


        </div>
    );
};

export default Courses;

const PaymentModal = () => {
    const { paymentdata, closeModal } = useGlobal();
    const handleFlutterPayment = useFlutterwave(paymentdata?.config)

    const payNow = () => {
        handleFlutterPayment({
            callback: (response) => {
                // closePaymentModal()
            },
            onClose: () => {
                window.location.replace("https://classroom.cholatrek.io")
                // setpaymentdata(null)
            },
        })
    }
    return (
        <Modal>
            <div className="installment">
                <div className="installment-heading">
                    <h4>Payment Details</h4>
                </div>
                <hr />
                <div className="installment-details">
                    <div className="installment-details-left">
                        <ul>
                            <li>Name</li>
                            <li>Email</li>
                            <li>Method</li>
                            <li>Amount</li>
                            <li>Payment Fee</li>
                        </ul>
                    </div>
                    <div className="installment-details-right">
                        <ul>
                            <li>{paymentdata.config.customer.name}</li>
                            <li>{paymentdata.config.customer.email}</li>
                            <li>{paymentdata.method}</li>
                            <li>{paymentdata.amount}</li>
                            <li>{paymentdata.paymentFee}</li>

                        </ul>
                    </div>
                </div>
                <div>
                    <button onClick={payNow}>Pay Now</button>
                    <button onClick={closeModal}> Close</button>
                </div>
            </div>
        </Modal>
    )
}
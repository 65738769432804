
import { Route, Routes } from 'react-router-dom';
import './styles/index.css';
import ClassroomLayoutExpert from './views/expertled/ClassroomLayout';
import ClassroomLayoutFree from './views/freecourses/ClassroomLayout';
import ClassroomLayoutCrash from './views/crashcourses/ClassroomLayout';

// import PrivateRoute from './services/PrivateRoute';
import Error404 from './views/pagenotfound/404';
import Page404Dashboard from './views/pagenotfound/404dashoard';
import { useGlobal } from './services/Context';
import TheLayout from './views/landing/TheLayout';
import SomethingWentWrong from './shared/SomethingWentWrong';
import { Loaders } from './shared/Loaders';
import { LoginAlert } from './shared/LoginAlert';
import PrivateRoute from './shared/PrivateRoute';
import { routes as expertroutes } from './views/expertled/routes/routes';
import { routes as crashroutes } from './views/crashcourses/routes/routes';
import { routes as freeroutes } from './views/freecourses/routes/routes';
import { routes } from './views/landing/routes/routes';
import Logout from './views/components/Logout';

function App() {
  const { currentUser, somethingwrong, setmsg, isLoading, showLoginAlert, msg, showmodal } = useGlobal()
  // const getLogged: any = localStorage.getItem('xu');
  // const logged = getLogged || null;
  // setCurrentUser(logged)


  window.addEventListener('offline', () => {
    setmsg("Hello Friend,  You are currently offline please do connect to a stable network to continue ")
  })

  window.addEventListener('online', () => {
    setmsg("Welcome back online")
    setTimeout(() => {
      setmsg("")
    }, 3000);
  })


  return (
    <div className="App">
      <Routes>

        <Route path="/expert-led/dashboard/:id" element={<PrivateRoute><ClassroomLayoutExpert /></PrivateRoute>}>
          {expertroutes.map((route: any, idx: React.Key | null | undefined) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                // exact={route.exact}
                // name={route.name}
                Component={route.component}
              />
            )
          })}
          <Route path="*" element={<Page404Dashboard />} />
        </Route>
        <Route path="/free/dashboard/:id" element={<PrivateRoute><ClassroomLayoutFree /></PrivateRoute>} >
          {freeroutes.map((route: any, idx: React.Key | null | undefined) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                // exact={route.exact}
                // name={route.name}
                Component={route.component}
              />
            )
          })}
          <Route path="*" element={<Page404Dashboard />} />
        </Route>
        <Route path="/crash/dashboard/:id" element={<PrivateRoute><ClassroomLayoutCrash /></PrivateRoute>}>
          {crashroutes.map((route: any, idx: React.Key | null | undefined) => {
            return route.component && (
              <Route
                key={idx}
                path={route.path}
                // exact={route.exact}
                // name={route.name}
                Component={route.component}
              />
            )
          })}
          <Route path="*" element={<Page404Dashboard />} />
        </Route>
        <Route path="/" element={<TheLayout />}>
          {routes.map((route: any, idx: React.Key | null | undefined) => {
            return (route.component && route.index) ? (
              <Route
                key={idx}
                index
                Component={route.component}
              />
            ) :
              (
                <Route
                  key={idx}
                  path={route.path}
                  Component={route.component}
                />
              )
          })}
        </Route>

        <Route path="*" element={<Error404 />} />

      </Routes>

      {/* {somethingwrong && <SomethingWentWrong />} */}
      {isLoading && <Loaders />}
      {showLoginAlert && <LoginAlert />}
      {
        msg && (
          <div className="err-msg" >
            <p>{msg}</p>
          </div>
        )
      }
      {showmodal.id === 1 && <Logout />}

    </div>
  );
}

export default App;


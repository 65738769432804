import React from "react"
import Modal from "./Modal"

export const LoginAlert = () => {
    return (
        <Modal>
            <div className="login-alert installment">
                <div className="login-alert--heading installment-heading">
                    <h4>Hello Friend</h4>
                </div>
                <hr />
                <div>
                    <h1>😓</h1>
                    <h4>You have to be logged in to proceed</h4>
                </div>
                <div>
                    <a href="https://cholatrek.io/login"><button>Login</button></a>
                </div><br />
                <small>Sure you're logged in ? <b style={{ cursor: 'pointer' }} onClick={() => window.location.reload()}>Refresh</b> </small>
            </div>
        </Modal>
    )
}
import React from 'react'
import { Route, Navigate } from 'react-router-dom'
import { useGlobal } from '../services/Context'
function PrivateRoute({ children }: any) {
    const { currentUser } = useGlobal()
    if (currentUser) {
        return children
    } else {
        return (
            <Navigate to="/" replace />
        )
    }

}

export default PrivateRoute

import * as IMAGES from '../../shared/images'
const getLogged: any = localStorage.getItem('xu');
const logged = getLogged || null
export const LandingData = [
    {
        title: 'Dashboard',
        hasSubMenu: false,
        link: `/`,
        icon: IMAGES.Dashboard,
    },
    {
        title: 'Profile',
        hasSubMenu: false,
        link: `/profile`,
        icon: IMAGES.Vector5,
    },
    {
        title: 'Update Profile',
        hasSubMenu: false,
        link: `/update-profile`,
        icon: IMAGES.Vector,
    },
    {
        title: 'Log Out',
        hasSubMenu: false,
        link: '#',
        icon: IMAGES.Logout,
    }
]

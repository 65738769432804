import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
const Profile = () => {
    const { dashboardData } = useGlobal()

    return (
        <div>

            <div className="landing" style={{ padding: "1em" }}>
                <div>
                    <div>
                        <Card>
                            <div className="profile-con">
                                <div className="profile--heading">
                                    <div>
                                        <img src={dashboardData?.data?.profileImg} alt="img" style={{ borderRadius: "50%", width: "100px", height: "100px" }} />
                                    </div>
                                    <div>
                                        <h4>{dashboardData?.data?.firstName} {dashboardData?.data?.lastName}</h4>
                                    </div>
                                </div>
                                <div className="profie--body">
                                    <div className="profile--body--user-details">
                                        <div>
                                            <div>
                                                <h4>User Details</h4>
                                            </div>
                                            <div className="profile--body--user-details--main">
                                                <div className="profile--body--user-details--main-col">
                                                    <div>
                                                        <b>Country</b>
                                                        <small>{dashboardData?.data?.country}.</small>
                                                    </div>
                                                    <div>
                                                        <b>Address</b>
                                                        <small>{dashboardData?.data?.address}.</small>
                                                    </div>
                                                    <div>
                                                        <b>Gender</b>
                                                        <small>{dashboardData?.data?.gender}.</small>
                                                    </div>
                                                </div>
                                                <div className="profile--body--user-details--main-col">
                                                    <div style={{ width: '100%'}}>
                                                        <b>Email</b>
                                                        <small>{dashboardData?.data?.email}.</small>
                                                    </div>
                                                    <div>
                                                        <b>Phone Number</b>
                                                        <small>{dashboardData?.data?.phone}.</small>
                                                    </div>
                                                    <div>
                                                        <b>LinkedIn Profile URL</b>
                                                        <small><a href={dashboardData?.data?.linkedin}>{dashboardData?.data?.linkedin}</a>.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </Card>
                    </div >
                </div >
            </div>
        </div>
    )
}

export default Profile
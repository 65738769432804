import React from 'react'
import { useGlobal } from '../../services/Context'
import Modal from '../../shared/Modal'
const Logout = () => {
    const { closeModal, logout } = useGlobal()

    return(
        <Modal>
            <h4>Are you sure you want to log out</h4>
            <div className="logout-btn">
                <button onClick={logout}>Yes</button>
                <button onClick={closeModal}>No</button>
            </div>
        </Modal>
    )
}

export default Logout
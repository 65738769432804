import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGlobal } from '../../services/Context';
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import Modal from '../../shared/Modal';

const Announcements = () => {
    const params: any = useParams()
    const { heading, showmodal, toggleModal, announcement, getAnnouncements } = useGlobal()

    const [current, setcurrent] = useState(null)
    useEffect(() => {
        if (announcement === null) {
            getAnnouncements(params.id)
        }
    }, [])
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Announcements</p>

                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con side-image">
                    <div className="reg-progress" style={{ width: "100%", position: "relative" }}>
                        <Card>
                            <div className="left">
                                <h4>Announcements</h4>
                                <p>The announcements forum is a special forum for general announcements.
                                    Your Success Manager will use this platform to notify you about module releases and
                                    announce any important course information.
                                </p>
                            </div>
                            <div className="right">
                                <img src={IMAGES.Announcement} style={{ width: "300px", position: "absolute", top: "-13%", right: '1%' }} alt="" />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="filter">
                <div className="filter-left">
                    <p>Sort By</p>

                    <button>Reset <img src={IMAGES.Drp} alt="drp-dwn" /></button>
                </div>
                <div className="filter-right">
                    <div className="header--navlinks" style={{ width: "100%" }} >
                        <div className="header--navlinks--search">
                            <button><img src={IMAGES.Magnify} /></button>
                            <input type="text" placeholder="Search courses" />
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <Card>
                    <div className="all-courses-con">
                        <Card>
                            {
                                announcement?.data?.map((item: any, idx: any) => (
                                    <div className="all-courses" key={idx}>
                                        <div className="course" style={{ flexWrap: "nowrap" }}>
                                            <div>
                                                <img src={IMAGES.MobileLogo} alt="img" style={{ borderRadius: "50%", width: "85px", height: "90px" }} />
                                            </div>
                                            <div>
                                                <h4 onClick={() => { setcurrent(item); toggleModal({ id: 3, name: "announcement" }) }} >{item.subject}</h4>
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                        <hr style={{ margin: '1em 0' }} />
                                    </div>
                                ))
                            }
                            {
                                announcement?.data?.length < 1 &&
                                <div style={{ textAlign: 'center' }}>
                                    <img src={IMAGES.Nodata} alt="empty" width="300px" />
                                    <h1>check back later 🥲</h1>
                                </div>
                            }
                            {/* <div className="all-courses">
                                <button>Make Subscription</button>
                            </div> */}
                        </Card>
                    </div>
                </Card>
            </div>
            {showmodal.id === 3 && <AnnouncementsModal item={current} />}

        </div>
    )
}

export default Announcements

const AnnouncementsModal = (props: any) => {
    const { toggleModal } = useGlobal()

    return (
        <Modal margin={true}>
            <div className="announcement">
                <div className="announcement-heading">
                    <h4>Announcement</h4>
                </div>
                <div>
                    <div className="announcement-card">
                        <div className="all-courses">
                            <div className="course" style={{ flexWrap: "nowrap" }}>
                                <div>
                                    <img src={IMAGES.MobileLogo} alt="img" style={{ borderRadius: "50%", width: "85px", height: "90px" }} />
                                </div>
                                <div>
                                    <h4>{props.item.subject}</h4>
                                    <p>{props.item.name}</p>
                                </div>
                            </div>
                            <hr style={{ margin: '1em 0' }} />
                            <div>
                                <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: props.item.message }}></p>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => toggleModal({ id: 0, name: "none" })}>Close</button>
                </div>
            </div>

        </Modal>
    )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


type Params = { id: string, week: string }
const Progress = () => {
    const {id , week} = useParams()
    const { heading, progress, getProgress } = useGlobal()
    const [isActive, setisActive] = useState(0)

    useEffect(() => {
        if (progress === null) {
            getProgress(id)
        }
    }, [])
    const data = progress?.data
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading({id , week})} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Progress</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con side-image">
                    <div className="reg-progress" style={{ width: "100%", position: "relative" }}>
                        <Card style={{ position: "relative" }}>
                            <div className="left">
                                <h4>Course Progress</h4>
                                <p>Track your course progress here
                                </p>
                            </div>
                            <div className="right">
                                <img src={IMAGES.Progress} alt="" style={{ width: "150px", position: "absolute", top: "-25%", right: '1%' }} />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div>
                <Card>
                    <div style={{ padding: '2em' }}>
                        <h4 style={{ margin: '0' }}>Total Progress</h4>
                        <div className="courses-progress">
                            <div className="progress--circle-wrap" style={{ fontWeight: 700 }} >
                                <CircularProgressbar value={data ? +data?.percentage : 0} text={`${data ? data?.percentage.toFixed(1) : 0}%`}
                                    strokeWidth={9}
                                    styles={buildStyles({
                                        // Text size
                                        textSize: '15px',
                                        // Colors
                                        pathColor: `#0A6DD8`,
                                        textColor: '#000',
                                        trailColor: '#DAD7FE',
                                        backgroundColor: '#DAD7FE',
                                    })}
                                />
                            </div>
                            <div className="progress--details">
                                <span>
                                    <h5>Current Week : </h5>
                                    <p>{data?.week}</p>
                                </span>
                                <span>
                                    <h5>Current Module : </h5>
                                    <p>{data?.module}</p>
                                </span>
                                <span>
                                    <h5>Current Lesson : </h5>
                                    <p>{data?.lesson}</p>
                                </span>
                            </div>
                        </div></div>
                </Card>
                <Card>
                    <div className="landing" style={{ padding: "1em 2em" }}>
                        {
                            data?.progress?.map((item: any) => (
                                <div key={item.week.weekId} className="technologies-categories--technology-category accordian--tab" style={{position:'relative'}}>
                                    <div className="accordian--tab__btn"style={{width:'100%'}} onClick={() => setisActive(isActive === 0 ? item.week.weekId : 0)}>
                                        <p>{`Week ${item.week.weekId}`}<p style={{position:'absolute',right:'0%',top:'0%', width:'170px'}}><p>Status : <b>{item.week.complete ? 'completed' : 'not completed'}</b></p></p></p>
                                    </div>
                                </div>
                            ))
                        }


                    </div>
                </Card>

            </div>
        </div>
    )
}

export default Progress

import React, { useEffect } from 'react'
import { routes } from './routes/routes'
import { useGlobal } from '../../services/Context'
import TheContent from '../../shared/TheContent'
import ClassroomHeader from '../../shared/Header'
import { LandngSidebar } from '../../shared/Sidebar'
import { Outlet } from 'react-router-dom'

const ClassroomLayout = () => {
    const { toggleSidebar, resetAll } = useGlobal();
    useEffect(() => {
        window.scrollTo({ top: 0 })
        resetAll()
    }, [])

    return (
        <div>
            <div>
                <ClassroomHeader />
            </div>
            <div className="main-body">
                {
                    toggleSidebar &&
                    <div id="sidebar">
                        <LandngSidebar />
                    </div>
                }

                <div id="main">
                    <main>
                        <Outlet/>
                        {/* <TheContent routes={routes} /> */}
                    </main>
                </div>
            </div>
            <div>
                {/* <Footer /> */}
            </div>
        </div>
    )
}

export default ClassroomLayout


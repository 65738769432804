import React from 'react'
import Footer from '../../shared/Footer'
import ClassroomHeader from '../../shared/Header';
import * as IMAGES from "../../shared/images";
import { useGlobal } from '../../services/Context';

const Page404Dashboard = () => {
   const { dashboardData } = useGlobal();

   return (
      <div className="landing" style={{ padding: '0' }}>
         <div style={{ width: "50%", margin: "3em auto" }}>
            <img src={IMAGES.Error} alt="" style={{ width: "100%" }} />
         </div>
      </div>
   )
}

export default Page404Dashboard

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useGlobal } from '../../services/Context';
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
const Grades = () => {
    const [activeTab, setactiveTab] = useState(0);
    const params: any = useParams()
    const { heading, grades, getGrades, courseData } = useGlobal()

    useEffect(() => {
        if (grades === null) {
            getGrades(params.id)
        }
    }, [])

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Grades</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con side-image">
                    <div className="reg-progress" style={{ width: "100%", position: "relative" }}>
                        <Card style={{ position: "relative" }}>
                            <div className="left">
                                <h4>Grades</h4>
                                <p>Check out your overall grades breakdown
                                </p>
                            </div>
                            <div className="right">
                                <img src={IMAGES.Grades} alt="" style={{ width: "220px", position: "absolute", top: "-31%", right: '1%' }} />
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="grades-tabs">
                    <button className="tab"
                        style={{
                            backgroundColor: activeTab === 0 ? "#0A6DD8" : "#ffffff",
                            color: activeTab === 0 ? "#ffffff" : "#0A6DD8"
                        }}
                        onClick={() => setactiveTab(0)}
                    >Overview Report</button>
                    <button className="tab"
                        style={{
                            backgroundColor: activeTab === 1 ? "#0A6DD8" : "#ffffff",
                            color: activeTab === 1 ? "#ffffff" : "#0A6DD8"
                        }}
                        onClick={() => setactiveTab(1)}
                    >User Report</button>
                </div>
                {
                    activeTab === 0 && (
                        <div className="grade-overview">
                            <div className="grade-tab course-name">
                                <button className="grade-tab--head">Course Name</button>
                                <div className="grade-tab--body">
                                    <h5>{courseData?.data?.title}</h5>
                                </div>
                            </div>
                            <div className="grade-tab score">
                                <button className="grade-tab--head" style={{ borderLeft: "0" }}>Grade</button>
                                <div className="grade-tab--body" style={{ borderLeft: "0" }}>
                                    <h5>{`${grades?.data?.average}`}%</h5>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    activeTab === 1 && (
                        <div>
                            <div className="grade-details">

                                {
                                    grades?.data?.grade?.map((item: any, index: any) => (
                                        <div className="grade-tab">
                                            <button className="grade-tab--head">Week {`${item.week}`}</button>
                                            <div className="grade-tab--body" key={index}>
                                                <div className="grade-tab--body-name">
                                                    <ul>
                                                        <li>Quiz</li>
                                                        <li>Project</li>
                                                    </ul>
                                                </div>
                                                <div className="grade-tab--body-percentile">
                                                    <div className="grade-tab--body-percentile-heading"><b>Percentage</b></div>
                                                    <ul>
                                                        <li>{item.score.quiz}%</li>
                                                        <li>{item.score.project}%</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Grades

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../../services/Context'
import Card from '../../../shared/Card'
import * as IMAGES from '../../../shared/images'

const Video = () => {
    const params: any = useParams()
    const scrollRef: any = useRef(null)
    const { heading, weekData, getWeek } = useGlobal()
    const isExpert = {
        courseId: params.id,
        weekId: +params.week,
        moduleId: +params.module,
        lessonId: +params.lesson
    }
    const isCrash = {
        courseId: params.id,
        moduleId: +params.module,
        lessonId: +params.lesson
    }
    const [part] = useState(params?.week ? isExpert : isCrash)
    const course = window.location.pathname.includes('expert-led') ? 'expert-led' : window.location.pathname.includes('crash') ? 'crash' : 'free'
    useEffect(() => {
        getWeek(part)
        sectionScroll()
    }, [])

    const sectionScroll = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    if (weekData && weekData?.data?.video) {
        return (
            <div>
                <div>
                    <Card>
                        <div className="expert-led--tag-con">
                            <div className="expert-led--tag">
                                <h4>{heading(params)} Course</h4>
                                <img src={IMAGES.Drpdown} alt=">" />
                                <p>Module</p>
                                <img src={IMAGES.Drpdown} alt=">" />
                                <p>Lesson</p>
                                <img src={IMAGES.Drpdown} alt=">" />
                                <p>Video</p>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                    <div className="reg-progress-con">
                        <div className="reg-progress" style={{ width: "100%" }}>
                            <Card>
                                <div>
                                    <div className="video-description--heading">
                                        <h4>{weekData?.data?.title}</h4>
                                        <b>•</b>
                                        <p>cholatrek</p>
                                    </div>
                                    <p> Welcome to Cholatrek Training Institute programming courses.</p>

                                    <div className="video-description--body">

                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                {
                    course === "expert-led" ?
                        <div className="back-button">
                            <Link to={`/${course}/dashboard/${params.id}/modules/week/${params?.week}/module/${params?.module}`}>
                                <button> Back</button>
                            </Link>
                        </div> :
                        <div className="back-button">
                            <Link to={`/${course}/dashboard/${params.id}/modules/${params?.module}`}>
                                <button> Back</button>
                            </Link>
                        </div>
                }
                <div>
                    <div className="unit-course" ref={scrollRef}>
                        <div className="unit-course--heading">
                            <div>
                                <h4>Video : {weekData?.data?.title} </h4>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusipiscing elit. Fus.</p> */}
                            </div>
                            {/* <p>Duration : {weekData?.data?.length}</p> */}
                        </div>
                        {/* <Card> */}
                        <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9', width: 'fit-content', height: 'fit-content' }}>
                            <div className="unit-course--body--unit" style={{ padding: '0', width: 'fit-content', height: 'fit-content' }}>
                                <div className="video-img" style={{ height: 'fit-content' }} >
                                    <Fragment key={weekData.data.video}>
                                        <video autoPlay controls >
                                            <source src={weekData.data.video} type="video/mp4" />
                                            Your browser does not support the video tag.type="video/mp4 "
                                        </video>
                                    </Fragment>
                                    {/* <iframe src={`https://www.w3schools.com/html/mov_bbb.mp4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                </div>
                            </div>
                        </div>
                        {/* </Card> */}
                    </div>
                </div>
            </div>
        )
    } else {
        return <> Loading...</>
    }
}

export default Video

import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../../services/Context'
import Card from '../../../shared/Card'
import * as IMAGES from '../../../shared/images'


const Assessment = () => {
    const params: any = useParams()
    const scrollRef: any = useRef(null)
    const { heading, weekData, getWeek } = useGlobal()
    const [part] = useState({
        courseId: params.id,
        weekId: +params.week,
        moduleId: +params.module,
        lessonId: +params.lesson
    })

    useEffect(() => {
        getWeek(part)
        sectionScroll()

    }, [])


    const sectionScroll = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    const course = window.location.pathname.includes('expert-led') ? 'expert-led' : window.location.pathname.includes('crash') ? 'crash' : 'free'

    if (weekData) {
        return (
            <div>
                <div>
                    <Card>
                        <div className="expert-led--tag-con">
                            <div className="expert-led--tag">
                                <h4>{heading(params)} Course</h4>
                                <img src={IMAGES.Drpdown} alt=">" />
                                <p>Module</p>
                                <img src={IMAGES.Drpdown} alt=">" />
                                <p>Lesson</p>
                            </div>
                        </div>
                    </Card>
                </div>
                <div>
                    <div className="reg-progress-con">
                        <div className="reg-progress" style={{ width: "100%" }}>
                            <Card>
                                <div>
                                    <h4>Assessment </h4>
                                    <p>{weekData?.data?.assessment?.title}</p>

                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                {
                    course === "expert-led" ?
                        <div className="back-button">
                            <Link to={`/${course}/dashboard/${params.id}/modules/week/${params?.week}/module/${params?.module}`}>
                                <button> Back</button>
                            </Link>
                        </div> :
                        <div className="back-button">
                            <Link to={`/${course}/dashboard/${params.id}/modules/${params?.module}`}>
                                <button> Back</button>
                            </Link>
                        </div>
                }
                <br /><br />
                <div>
                    <div className="reg-progress-con">
                        <div className="reg-progress" style={{ width: "100%" }} ref={scrollRef}>
                            <Card>
                                <embed src={weekData?.data?.assessment?.link} type="application/pdf" height="800" width="100%"></embed>

                            </Card>
                        </div>
                    </div>
                </div><br /><br />
            </div>
        )
    } else {
        return <>Loading...</>
    }
}

export default Assessment
// "https://www.typescriptlang.org/assets/typescript-handbook.pdf"

import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'


type Params = { course: string, id: string }

const ExpertLed = () => {
    const { course, id } = useParams()
    const { heading } = useGlobal()

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading({course,id})} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Get Started</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con-dash">
                    <div className="reg-progress">
                        <Card>
                            <div>
                                <h4>Honor Code</h4>
                                <p>Read Cholatrek Training Institute Honour code to receive access to the Orientation</p>
                                <p>Click enter to read</p>
                                <hr />
                                <Link to={`/${course}/dashboard/${id}/modules/honour-code`}>
                                    <button>Enter</button>
                                </Link>

                            </div>
                        </Card>
                    </div>
                    <div className="reg-progress sec">
                        <Card>
                            <div>
                                <h4>Orientation</h4>
                                <p>Welcome to Cholatrek Training Institute programming course</p>
                                <p>Click enter to open</p>
                                <hr />
                                <Link to={`/${course}/dashboard/${id}/modules/orientation`}>
                                    <button>Enter</button>
                                </Link>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExpertLed

import React, { useEffect } from 'react'
import ClassroomHeader from '../../shared/Header'
import Footer from '../../shared/Footer'
import TheContent from '../../shared/TheContent'
import Sidebar from '../../shared/Sidebar'
import { routes } from './routes/routes'
// import { Data } from './_sidebar'
import { useGlobal } from '../../services/Context'
import { Loaders } from '../../shared/Loaders'
import { useParams } from 'react-router'
import * as IMAGES from '../../shared/images'
import { Modules, Weeks } from '../../types/course'
import { Outlet } from 'react-router-dom'

type Params = { id: string, module: string }
const ClassroomLayout = () => {
    const getLogged: any = localStorage.getItem('xu');
    const logged = getLogged || null
    const params: any = useParams()
    const { toggleSidebar, isLoading, resumeCourse, courseData } = useGlobal()

    useEffect(() => {
        window.scrollTo({ top: 0 })
        if (courseData === null) {
            resume(params.id, "expert-led")
        }
    }, [])

    const resume = (id: string, route: string) => {
        resumeCourse(id, route)
    }

    // filter out the needinfo for each week on the sidebar
    const modules = courseData?.data?.weeks?.map((item: Weeks) => {
        return {
            courseId: courseData?.data?._id,
            type: "expert",
            title: `Week ${item.weekId}`,
            hasSubMenu: true,
            link: `modules/week/${item.weekId}`,
            icon: IMAGES.Vector,
            week: item.weekId,
            isActive: item.isActive,
            cohort: item.cohort,
            modules: item?.modules?.map(((module: Modules) => {
                return {
                    title: `Module ${module.moduleId}`,
                    hasSubMenu: true,
                    link: `modules/week/${item.weekId}/module/${module.moduleId}`,
                    icon: IMAGES.Vector,
                    moduleId: module.moduleId,
                    isActive: module.isActive,
                }
            }))
        }

    }) || []

    const Data = [
        {
            title: 'Dashboard',
            hasSubMenu: false,
            link: `/${logged}`,
            home: true,
            icon: IMAGES.Dashboard,
        },
        {
            title: 'Get Started',
            hasSubMenu: false,
            link: 'main',
            icon: IMAGES.Home,
        },
        {
            title: 'Modules',
            hasSubMenu: true,
            subNum: 1,
            link: 'modules',
            icon: IMAGES.Vector,
            subMenu: [{
                title: 'Honour Code',
                hasSubMenu: false,
                link: 'modules/honour-code',
                icon: IMAGES.Vector,
            },
            {
                title: 'Orientation',
                hasSubMenu: false,
                link: 'modules/orientation',
                icon: IMAGES.Vector,
            },
            ...modules
            ]
        },
        {
            title: 'Announcement',
            hasSubMenu: false,
            link: 'announcements',
            icon: IMAGES.Vector1,
        },
        {
            title: 'Classmates',
            hasSubMenu: false,
            link: 'classmates',
            icon: IMAGES.Vector4,
        },
        {
            title: 'Contact Support Team',
            hasSubMenu: false,
            link: 'support',
            icon: IMAGES.Vector5,
        },
        {
            title: 'Grade',
            hasSubMenu: false,
            link: 'grades',
            icon: IMAGES.Vector2,
        },
        // {
        //     title:'Calendar',
        //     hasSubMenu: false,
        //     link:'none',
        //     icon:IMAGES.Vector6,
        // },
        {
            title: 'Progress',
            hasSubMenu: false,
            link: 'progress',
            icon: IMAGES.Vector3,
        },
        {
            title: 'Log Out',
            hasSubMenu: false,
            link: '#',
            icon: IMAGES.Logout,
        }
    ]
    return (
        <div>
            <div>
                <ClassroomHeader />
            </div>
            <div className="main-body">
                {
                    toggleSidebar &&
                    <div id="sidebar">
                        <Sidebar Data={Data} modules={modules} id={params?.id} module={params?.module} path="expert-led" />
                    </div>
                }

                <div id="main">
                    <main>
                        <Outlet/>
                        {/* <TheContent routes={routes} /> */}
                    </main>
                </div>
                {isLoading && <Loaders />}
            </div>
            <div>
                {/* <Footer /> */}
            </div>
        </div>
    )
}

export default ClassroomLayout

import Announcements from "../../components/Announcements";
import Classmates from "../../components/Classmates";
import ExpertLed from "../../components/Dashboard";
import Grades from "../../components/Grades";
import HonourCode from "../../components/HonurCode";
import Orientation from "../../components/Orientation";
import OrientationVideo from "../../components/OrientationVideo";
import Video from "../../components/lesson-types/Video";
import Notes from "../../components/lesson-types/Notes";
import Profile from "../../components/Profile";
import Progress from "../../components/Progress";
import Support from "../../components/Support";
import RenderWeek from "../Week";
import Assessment from "../../components/lesson-types/Assessment";
import Project from "../../components/Project";
import Tests from "../../components/lesson-types/Tests";
import Infographics from "../../components/lesson-types/Infographics";
import Activities from "../../components/lesson-types/Activities";
import Call from "../../components/lesson-types/Call";
// import Week from "../../components/Week";
export const routes = [
    { path: '', exact: true, name: 'Landing' },
    { path: 'main/', exact: true, name: 'Dashboard', component: ExpertLed },
    { path: 'modules/honour-code', exact: true, name: 'HonorCode', component: HonourCode },
    { path: 'modules/orientation', exact: true, name: 'Orientation', component: Orientation },
    { path: 'modules/week/:week/module/:module', exact: true, name: 'Week', component: RenderWeek },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/video', exact: true, name: 'video', component: Video },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/notes', exact: true, name: 'notes', component: Notes },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/test', exact: true, name: 'tests', component: Tests },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/infographic', exact: true, name: 'infographics', component: Infographics },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/assessment', exact: true, name: 'assessment', component: Assessment },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/activities', exact: true, name: 'activities', component: Activities },
    { path: 'modules/week/:week/module/:module/lesson/:lesson/call', exact: true, name: 'weekly call', component: Call },
    { path: 'modules/week/:week/module/:module/project', exact: true, name: 'project', component: Project },
    { path: 'modules/orientation/video/:video', exact: true, name: 'Week', component: OrientationVideo },
    { path: 'announcements', exact: true, name: 'Announcements', component: Announcements },
    { path: 'classmates', exact: true, name: 'Classmates', component: Classmates },
    { path: 'classmates/profile/:person', exact: true, name: 'Profile', component: Profile },
    { path: 'support', exact: true, name: 'Support', component: Support },
    { path: 'grades', exact: true, name: 'Grades', component: Grades },
    { path: 'progress', exact: true, name: 'Progress', component: Progress },

];
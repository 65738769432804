import React, { useEffect } from 'react'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import { Link, useParams } from 'react-router-dom';
import { useGlobal } from '../../services/Context';

const Classmates = () => {
    const params: any = useParams()
    const { heading, classmates, getClassmates } = useGlobal()

    useEffect(() => {
        if (classmates === null) {
            getClassmates(params.id)
        }
    }, [])

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Classmates</p>

                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con side-image">
                    <div className="reg-progress" style={{ width: "100%", position: "relative" }}>
                        <Card style={{ position: "relative" }}>
                            <div className="left">
                                <h4>Classmates</h4>
                                <p>Meet your classmates
                                </p>
                            </div>
                            <div className="right">
                                <img src={IMAGES.Student} alt="" style={{ width: "120px", position: "absolute", top: "0%", right: '1%' }} />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div style={{ margin: '3em' }}></div>
            <div >
                <div className="classmate-list">
                    {
                        classmates?.data?.map((item: any, index: any) => (
                            <div className="classmate" key={index}>
                                <Link to={`/${params.course}/dashboard/${params.id}/classmates/profile/${item._id}`}>
                                    <Card>
                                        <img src={item.profileImg} alt="" />
                                        <div className="classmate-details">
                                            <h4>{`${item.firstName} ${item.lastName}`}</h4>
                                            <span>Student</span>
                                            <b>{item.method}</b>
                                        </div>
                                    </Card>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div >
    )
}

export default Classmates

import React, { useEffect, useState } from 'react'
import * as IMAGES from "./images"
import { Link, useNavigate } from 'react-router-dom'
import { useGlobal } from '../services/Context'
const ClassroomHeader = () => {
    const [togleNav, settogleNav] = useState(false)
    const { sidebarControl, dashboardData, resetAll } = useGlobal()
    const history = useNavigate()
    const [data, setdata]: any = useState(null)

    useEffect(() => {
        setdata(dashboardData?.data)
    }, [dashboardData])
    const toggleNav = () => {
        settogleNav(current => !current)
    }
    const sidebarToggle = () => {
        // if (window.innerWidth < 800) { 
        sidebarControl()
        // }
    }
    const gotoLanding = () => {
        resetAll();
        history(`/`)
    }

    console.log(data)
    return (
        <header className="classroom-header">
            <div className="header">
                <div className="header--logo">
                    <div className="header--logo--desktop">
                        <img src={IMAGES.DesktopLogo} alt="logo" style={{ width: '170px' }} onClick={gotoLanding} />
                    </div>
                    <div className="header--logo--mobile" >
                        <div className="header--logo--mobile-toggle" onClick={sidebarToggle}>
                            <img src={IMAGES.MobileNav} alt="nav" />
                            <span>menu</span>
                        </div>
                        <img src={IMAGES.MobileLogo} alt="logo" style={{ margin: "0 0 0 1em", width: "50px" }} onClick={gotoLanding} />
                    </div>
                </div>
                <div className="header--navlinks--mobile" style={{ display: togleNav ? 'block' : 'none' }}>
                    <div className="header--navlinks--mobile--">
                        <div className="header--navlinks--mobile__close">
                            <b onClick={toggleNav}>x</b>
                        </div>
                    </div>
                </div>
                {/* <div className="header--navlinks" style={{ width: '68%' }}>
                    <div className="header--navlinks--search">
                        <button><img src={IMAGES.Magnify} /></button>
                        <input type="text" placeholder="Search courses" />
                    </div>
                </div> */}

                {/* <div className="header--btns" style={{ width: '12%' }}>

                    <div className="header--btns--desktop" >

                        <div className="header--btns--desktop__signin header--btns--desktop--img"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src="" alt="" />
                            <img src={data?.profileImg} alt="" style={{ marginRight: '5px' }} />
                            <img src={IMAGES.Drpdown} alt="" />
                            <div></div>
                        </div>
                        <div className="navlinks">

                        </div>
                    </div>
                </div> */}

                <div className="header--btns">
                    <div className="header--btns--desktop" style={{ display: "flex" }}>
                        <div className="header--btns--desktop__signin header--btns--desktop--img"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src="" alt="" />
                            <img src={data?.profileImg} alt="" style={{ marginRight: '5px' }} />
                            <img src={IMAGES.Drpdown} alt="" />
                        </div>
                    </div>
                    <div className="user-drpdwn">
                        <div>
                            <>
                                <div>
                                    <img
                                        src={data?.profileImg}
                                        alt="profile pic"
                                        style={{
                                            width: "70px",
                                            margin: "0.2em 0 1em 0",
                                            border: "0px solid #0A6DD8",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </div>
                                <h5 style={{ margin: "0" }}>
                                    {`${data?.firstName} ${data?.lastName}`}
                                </h5>
                            </>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link to="/" style={{display:'flex', alignItems:'center', gap:'1em'}} >
                                        {/* <img src={IMAGES.Icon1} alt="img" />{" "} */}
                                        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.2384 0.13623H0.867276C0.645807 0.13623 0.433409 0.224209 0.276807 0.380811C0.120205 0.537413 0.0322266 0.749811 0.0322266 0.97128L0.0322266 16.0022C0.0322266 16.2236 0.120205 16.436 0.276807 16.5926C0.433409 16.7492 0.645807 16.8372 0.867276 16.8372H19.2384C19.4598 16.8372 19.6722 16.7492 19.8288 16.5926C19.9854 16.436 20.0734 16.2236 20.0734 16.0022V0.97128C20.0734 0.749811 19.9854 0.537413 19.8288 0.380811C19.6722 0.224209 19.4598 0.13623 19.2384 0.13623ZM18.4033 15.1671H16.7332V14.3321H12.558V15.1671H1.70233V1.80633H18.4033V15.1671ZM8.62489 6.57446C8.62489 6.19575 8.77533 5.83255 9.04312 5.56476C9.31091 5.29697 9.67411 5.14653 10.0528 5.14653C10.8461 5.14653 11.4808 5.78952 11.4808 6.57446C11.4808 7.36776 10.8461 8.01075 10.0528 8.01075C9.25953 8.01075 8.62489 7.36776 8.62489 6.57446ZM4.80036 7.89384C4.80036 7.30096 5.28469 6.81663 5.87757 6.81663C6.16327 6.81663 6.43726 6.93012 6.63928 7.13214C6.8413 7.33415 6.95479 7.60815 6.95479 7.89384C6.95479 8.48673 6.47046 8.96271 5.87757 8.96271C5.28469 8.96271 4.80036 8.48673 4.80036 7.89384ZM13.1509 7.89384C13.1509 7.60815 13.2643 7.33415 13.4664 7.13214C13.6684 6.93012 13.9424 6.81663 14.2281 6.81663C14.5138 6.81663 14.7878 6.93012 14.9898 7.13214C15.1918 7.33415 15.3053 7.60815 15.3053 7.89384C15.3053 8.48673 14.821 8.96271 14.2281 8.96271C13.6352 8.96271 13.1509 8.48673 13.1509 7.89384ZM16.7332 11.1088V11.8269H3.37243V11.1088C3.37243 10.3238 4.66675 9.68085 5.87757 9.68085C6.33685 9.68085 6.80448 9.77271 7.21365 9.93136C7.83994 9.35518 8.96726 8.96271 10.0528 8.96271C11.1384 8.96271 12.2657 9.35518 12.892 9.93136C13.3012 9.77271 13.7688 9.68085 14.2281 9.68085C15.4389 9.68085 16.7332 10.3238 16.7332 11.1088Z" fill="#0A6DD8" />
                                        </svg>
                                        <b>View My Courses</b>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="header--btns--mobile">

                        <div className="header--btns--desktop" style={{ display: "flex" }}>

                            <div className="header--btns--desktop__signin header--btns--desktop--img"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img src="" alt="" />
                                <img src={data?.profileImg} alt="" style={{ marginRight: '5px' }} />
                                <img src={IMAGES.Drpdown} alt="" />
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        </header>
    )
}

export default ClassroomHeader


// <div className="header--btns" style={{ width: "12%" }}>
// <div className="header--btns--desktop">
//    <div
//       className="header--btns--desktop__signin header--btns--desktop--img"
//       style={{
//          display: "flex",
//          alignItems: "center",
//       }}
//    >
//       <img src="" alt="" />
//       <img
//          src={
//             currentUser.photoURL
//                ? currentUser.photoUrl
//                : IMAGES.UserPic
//          }
//          alt=""
//       />
//       <img src={IMAGES.Drpdown} alt="" />
//    </div>
// </div>
// <div className="user-drpdwn">
//    <div>
//       <>
//          <div>
//             <img
//                src={IMAGES.UserPic}
//                alt=""
//                style={{
//                   width: "70px",
//                   margin: "0.2em 0 1em 0",
//                   border: "0px solid #0A6DD8",
//                   borderRadius: "50%",
//                }}
//             />
//          </div>
//          <h5 style={{ margin: "0" }}>
//             {`${currentUser?.data?.firstName} ${currentUser?.data?.lastName}`}
//          </h5>
//       </>
//    </div>
//    <div>
//       <ul>
//          <li>
//             <a href={classroomurl} target="_blank" rel="noreferrer" >
//                <img src={IMAGES.Icon1} alt="img" />{" "}
//                <b>View Classroom</b>
//             </a>
//          </li>
//          <li>
//             <Link to="/update-password">
//                <img src={IMAGES.Icon1} alt="img" />{" "}
//                <b>Update Password</b>
//             </Link>
//          </li>
//          <li onClick={logout}>
//             <img src={IMAGES.Icon2} alt="img" /> <b>Logout</b>
//          </li>
//       </ul>
//    </div>
// </div>
// </div>

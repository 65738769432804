import Error404 from '../../pagenotfound/404';
import Courses from '../Courses';
import Profile from '../Profile';
import UpdateProfile from '../UpdateProfile';

export const routes = [
    { path: '/', exact: true, name: 'Dashboard', component: Courses, index: true },
    { path: '/profile', exact: true, name: 'HonorCode', component: Profile },
    { path: '/update-profile', exact: true, name: 'HonorCode', component: UpdateProfile },
    // { path: '/', name: 'Dashboard', component: Error404 },
];
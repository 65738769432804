import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
const Orientation = () => {
    const params: any = useParams()
    const { heading, landingData } = useGlobal()

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Module</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Orientation</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <div>
                                <h4>Orientation</h4>
                                <p>Welcome to Cholatrek Training Institute programming courses</p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div>
                <div className="unit-course">
                    <div className="unit-course--heading">
                        <div>
                            <h4> </h4>
                        </div>
                        <p style={{ float: 'right' }}></p>
                    </div>
                    {/* <Card> */}
                    <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9' }}>
                        {
                            landingData?.data?.orientation?.map((item: any, idx: any) => (
                                <Link to={`/${params.course}/dashboard/${params.id}/modules/orientation/video/${item._id}`} key={idx} >
                                    <div className="unit-course--body--unit">
                                        <div className="unit-course--body--unit-img">
                                            <img src={IMAGES.Vector7} alt="img" />
                                        </div>
                                        <div className="unit-course--body--unit-description">
                                            <h5>0.{idx + 1} Video</h5>
                                            <b>{item.title}</b>
                                            <span style={{color:'black'}}>[{item.length}]</span>
                                        </div>
                                        <div className="unit-course--body--unit-check">
                                            <img src={IMAGES.Vector9} />
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }

                    </div>
                    {/* </Card> */}
                </div>
            </div>

        </div>
    )
}

export default Orientation

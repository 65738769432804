import React from 'react'
const Card = (props: any) => {
    const style = {
        borderRadius: '10px',
        boxShadow: '0px 0px 20px rgba(8, 96, 208, 0.1)',          
    }
    return (
        <div className="classroom-card" style={style}>
            {props.children}
        </div>
    )
}

export default Card

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import { Lesson, Weeks } from '../../types/course'

type Params = { id: string, module: string, course: string, week: string }

const Week = () => {
    const params: any = useParams()
    const { heading, weekData, getWeek, submitAssignment, courseData, dashboardData } = useGlobal()
    const [assignment, setassignment] = useState("")
    const [part, setpart] = useState({
        courseId: params.id,
        weekId: +params.week,
        moduleId: +params.module,
        // lessonId: 1
    })
    const history = useNavigate()
    const project = courseData?.data?.weeks.map((elem: Weeks) => { if (elem.weekId === +params.week) { return elem } })[0]?.project
    useEffect(() => {
        // if (weekData === null) {
        getWeek(part)
        // }
    }, [part])

    const prev = () => {
        if (+weekData?.data?.moduleId <= 1) {
            history(`/expert-led/dashboard/${params.id}/modules/orientation`)
        } else {
            setpart({ ...part, moduleId: +weekData?.data?.moduleId - 1 })
            history(`/expert-led/dashboard/${params.id}/modules/week/${+params.week}/module/${+weekData?.data?.moduleId - 1}`)
        }
    }
    const next = () => {
        if (weekData?.data?.isLastModule === false) {
            setpart({ ...part, moduleId: +weekData?.data?.moduleId + 1 })
            history(`/expert-led/dashboard/${params.id}/modules/week/${+params.week}/module/${+weekData?.data?.moduleId + 1}`)
        } else {
            setpart({ ...part, weekId: +params.week + 1, moduleId: 1 })
            history(`/expert-led/dashboard/${params.id}/modules/week/${+params.week + 1}/module/${1}`)
        }
    }
    const submit = () => {
        const body = {
            courseId: params.id,
            assignment: assignment,
            weekId: +params.week,
        }
        submitAssignment(body);
    }
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Module</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Week {params?.week}</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <div>
                                <h4>Module {weekData?.data?.moduleId} : {weekData?.data?.title}</h4>
                                <p>Welcome to Cholatrek Training Institute programming courses</p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div>
                {
                    weekData?.data?.lessons?.map((item: Lesson) => (
                        <div className="unit-course" key={item._id}>
                            <div className="unit-course--heading">
                                <div>
                                    <h4>{item.title}</h4>
                                    {/* <h4>Lesson {item.lessonId} </h4> */}
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusipiscing elit. Fus.</p> */}
                                </div>
                                {/* <p>Duration : 1hr 40mins</p> */}
                            </div>
                            {/* <Card> */}
                            <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9' }}>
                                {
                                    item.video && (
                                        <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/video`}>
                                            <div className="unit-course--body--unit">
                                                <div className="unit-course--body--unit-img">
                                                    <img src={IMAGES.Vector7} alt="img" />
                                                </div>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Video</h5>
                                                    <b>{item.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                                <div className="unit-course--body--unit-check">
                                                    {
                                                        (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                                {
                                    item.notes && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector10} alt="img" />
                                            </div>
                                            <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/notes`}>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Note</h5>
                                                    <b>{item.notes.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                            </Link>
                                            <div className="unit-course--body--unit-check">
                                                <a href={item.notes.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item.assessment && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector8} alt="img" />
                                            </div>
                                            <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/assessment`}>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Assessment</h5>
                                                    <b>{item.assessment.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                            </Link>
                                            <div className="unit-course--body--unit-check">
                                                <a href={item.assessment.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item?.test && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector8} alt="img" />
                                            </div>
                                            <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/test`}>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Test</h5>
                                                    <b>{item.test.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                            </Link>
                                            <div className="unit-course--body--unit-check">
                                                <a href={item.test.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item.infographics && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector10} alt="img" />
                                            </div>
                                            <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/infographic`}>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Infographic</h5>
                                                    <b>{item.infographics.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                            </Link>
                                            <div className="unit-course--body--unit-check">
                                                <a href={item.infographics.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item.quiz && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector8} alt="img" />
                                            </div>
                                            <div className="unit-course--body--unit-description">
                                                <h5>0.1 Quiz</h5>
                                                <b>{item.title}</b>
                                                {/* <span>[0.5mins]</span> */}
                                            </div>
                                            <div className="unit-course--body--unit-check">
                                                <img src={IMAGES.Vector11} />
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item?.activities && (
                                        <div className="unit-course--body--unit">
                                            <div className="unit-course--body--unit-img">
                                                <img src={IMAGES.Vector8} alt="img" />
                                            </div>
                                            <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/activities`}>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Activities</h5>
                                                    <b>{item.activities.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                            </Link>
                                            <div className="unit-course--body--unit-check">
                                                <a href={item.activities.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                                {
                                                    (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    item.call && (
                                        <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/lesson/${item.lessonId}/call`}>
                                            <div className="unit-course--body--unit">
                                                <div className="unit-course--body--unit-img">
                                                    <img src={IMAGES.Vector7} alt="img" />
                                                </div>
                                                <div className="unit-course--body--unit-description">
                                                    <h5>0.1 Weekly Call</h5>
                                                    <b>{item.call.title}</b>
                                                    {/* <span>[0.5mins]</span> */}
                                                </div>
                                                <div className="unit-course--body--unit-check">
                                                    {
                                                        (item?.graduates?.indexOf(dashboardData?.data?._id) !== -1) ? <img src={IMAGES.Vector9} /> : <img src={IMAGES.Vector13} />
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                            </div>
                            {/* </Card> */}
                        </div>
                    ))
                }
                {
                    weekData?.data?.isLastModule && project && project?.link &&
                    <div className="unit-course">
                        <div className="unit-course--heading">
                            <div>
                                <h4>Project</h4>
                            </div>
                            <p>Deadline: {project?.deadline?.slice(0, 10)}</p>
                        </div>
                        <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9' }}>
                            <div className="unit-course--body--unit">
                                <div className="unit-course--body--unit-img">
                                    <img src={IMAGES.Vector10} alt="img" />
                                </div>
                                <Link to={`/expert-led/dashboard/${params.id}/modules/week/${params.week}/module/${params.module}/project`}>
                                    <div className="unit-course--body--unit-description">
                                        <h5>0.1 Project</h5>
                                        <b>{project?.title}</b>
                                        {/* <span>[0.5mins]</span> */}
                                    </div>
                                </Link>
                                <div className="unit-course--body--unit-check">
                                    <a href={project?.link} download target="_blank" rel="noreferrer" ><img src={IMAGES.Vector11} /></a>
                                    <img src={IMAGES.Vector9} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    weekData?.data?.isLastModule &&
                    <div className="unit-course">
                        <div className="unit-course--heading">
                            <div>
                                <h4>Upload Project Link </h4>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusipiscing elit. Fus.</p> */}
                            </div>
                            {/* <p>Duration : 1hr 40mins</p> */}
                        </div>
                        {/* <Card> */}
                        <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9' }}>
                            <div className="unit-course--body--unit">
                                <div className="unit-course--body--unit-img" style={{ display: window.innerWidth > 500 ? 'block' : 'none' }}>
                                    <img src={IMAGES.Vector12} alt="img" />
                                </div>
                                <div className="unit-course--body--unit-description">
                                    <h5 style={{ display: window.innerWidth > 500 ? 'block' : 'none' }}></h5>
                                    <input placeholder="Enter Link" value={assignment} onChange={(e) => setassignment(e.target.value)} style={{ color: 'black' }} />
                                    <button onClick={submit}>Upload</button>

                                </div>
                            </div>
                        </div>
                        {/* </Card> */}
                    </div>
                }
            </div>
            <div className="last-module">
                <button className="first"
                    onClick={prev}
                >{+weekData?.data?.moduleId === 1 || !weekData ? 'Orientation' : `Module ${+weekData?.data?.moduleId - 1}`}</button>
                <button className="last"
                    onClick={next}
                > {weekData?.data?.isLastModule || !weekData ? `Week ${+params?.week + 1}` : `Module ${weekData?.data?.moduleId + 1}`}</button>
            </div>
        </div >
    )
}

export default Week


import React, { useContext, useEffect, useState } from 'react'
import Cookies from 'universal-cookie';
import { getRequest, patchFormRequest, axiosPostRequest } from './api'
import { feedback } from './feedback';

const GlobalContext = React.createContext<any | null>(null)

export const useGlobal = () => {
    const globalContext: any = useContext(GlobalContext)
    return globalContext
}



const Context = ({ children }: any) => {
    const cookies = new Cookies()

    // get currently logged user 
    const logged = cookies.get('token');
    const mainpge = "https://cholatrek.io/login";

    const [currentUser, setCurrentUser]: any = useState(logged)
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingBtn, setisLoadingBtn] = useState(false)
    const [showmodal, setshowmodal] = useState({ id: 0, name: 'none' })
    const [toggleSidebar, settoggleSidebar] = useState(true)
    const [msg, setmsg] = useState<string | null>(null) //for all response from endpoint
    const [somethingwrong, setsomethingwrong] = useState(false)
    const [showLoginAlert, setShowLoginAlert] = useState<boolean>(false)
    const [refreshCount, setRefreshCount] = useState(0)

    // state for data gotten
    const [landingData, setlandingData] = useState(null)
    const [dashboardData, setdashboardData] = useState(null)
    const [courseData, setcourseData] = useState(null)
    const [paymentdata, setpaymentdata] = useState(null)
    const [weekData, setweekData] = useState(null)
    const [classmates, setclassmates] = useState(null)
    const [announcement, setannouncement] = useState(null)
    const [grades, setgrades] = useState(null)
    const [support, setsupport] = useState(null)
    const [progress, setprogress] = useState(null)


    useEffect(() => {
        setCurrentUser(logged)
        if (dashboardData === null) getDashboard();
        if (window.innerWidth < 800) {
            settoggleSidebar(false)
        }
    }, [dashboardData, refreshCount])


    const toggleModal = (current: React.SetStateAction<{ id: number; name: string; }>) => {
        setshowmodal(current)
    }
    const closeModal = () => {
        toggleModal({ id: 0, name: 'none' })
    }
    const sidebarControl = () => {
        settoggleSidebar(current => !current)
    }
    const toggleErrorModal = () => {
        setsomethingwrong(current => !current)
    }
    // changes loader state and visibility
    const startLoading = () => {
        setisLoading(true)
    }
    const endLoading = () => {
        setisLoading(false)
    }
    const startLoadingBtn = () => {
        setisLoadingBtn(true)
    }
    const endLoadingBtn = () => {
        setisLoadingBtn(false)
    }
    // heading is bascially a function that uses the paramenter from route to get the course type
    const heading = (params: any) => {
        const course: any = params.course?.split('-') || params.id?.split('-');
        let c: string = course[0] + " "
        let d = course[1] ? course[1] : ""
        return d
    }

    const feedbackMsg = (label: string) => {
        let res;
        Object.entries(feedback).forEach(([key, value]) => {
            if (key === label) {
                console.log(`feedback`, value)
                res = value
            }
        })
        return res
    }

    function useForceUpdate() {
        const [_, setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update the state to force render
    }

    // get landing data
    const getLanding = () => {
        startLoading()
        const url = "admin/landing"
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setlandingData(res)
                } else {
                    console.log(res)
                    setmsg(feedbackMsg(res.error) || null)
                    setTimeout(() => {
                        setmsg(null)
                    }, 8000);
                }

            })
            .catch(err => {
                setmsg(err?.message)
            })
    }

    // get all dashboard details
    const getDashboard = () => {
        startLoading()
        const url = "user/classroom/dashboard"
        getRequest(url)
            .then(res => {
                if (res.status) {
                    setShowLoginAlert(false)
                    setdashboardData(res)
                    getLanding()
                    endLoading()
                } else {
                    // setmsg(feedbackMsg(res.error) || null)
                    // setTimeout(() => {
                    //     setmsg(null)
                    // }, 8000);
                    if (res.errors === "INVALID_TOKEN_ERROR" || res.error === "TOKEN_ERROR") {
                        if (refreshCount < 2) {
                            setRefreshCount(current => current + 1)
                        } else {
                            setShowLoginAlert(true)
                        }
                    }
                    endLoading()

                }


            })
            .catch(err => {
                endLoading()
                setmsg("Hello Friend, Something went wrong. plesase do check if you have a stable internet connection.. and refresh")
                // toggleErrorModal()
            })
    }

    // resume a course
    const resumeCourse = (id: string) => {
        startLoading()
        const url = `user/classroom/course/${id}`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setcourseData(res)
                    // history(`/${route}/dashboard/${id}/main`)
                } else {
                    setmsg(feedbackMsg(res.error) || null)
                    setTimeout(() => {
                        setmsg(null)
                    }, 8000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }

    // get parts of a week 
    const getWeek = async (body: any) => {
        startLoading()
        const url = `user/classroom/course/sort`
        const response = await axiosPostRequest(url, body)
            .then(res => {
                endLoading()
                if (res.status) {
                    setweekData(res.data)
                } else {
                    setmsg(feedbackMsg(res?.data?.error) || null)
                    setTimeout(() => {
                        setmsg(null)

                    }, 8000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(feedbackMsg(err?.response?.data?.error) || null)

                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
        return response
    }

    // get classmates
    const getClassmates = (id: string) => {
        startLoading()
        const url = `user/classroom/course/${id}/classmates`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setclassmates(res)
                } else if (!res.status) {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })

    }

    // get announcements
    const getAnnouncements = (id: string) => {
        startLoading()
        const url = `user/classroom/course/${id}/announcement`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setannouncement(res)
                } else if (!res.status) {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })

    }

    // get grades
    const getGrades = (id: string) => {
        startLoading()
        const url = `user/classroom/course/${id}/grades`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setgrades(res)
                } else if (!res.status) {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })

    }

    // get support
    const getSupportTeam = (id: string) => {
        startLoading()
        const url = `user/classroom/course/${id}/help`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setsupport(res)
                } else if (!res.status) {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })

    }

    // track progress
    const getProgress = (id: string | number) => {
        startLoading()
        const url = `user/classroom/course/${id}/progress`
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    setprogress(res)
                } else if (!res.status) {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }
    // contact support
    const contactSupport = async (body: any) => {
        console.log(body)
        startLoading()
        const url = "user/classroom/course/support"
        await axiosPostRequest(url, body)
            .then(res => {
                endLoading()
                if (res.status) {
                    let success = feedbackMsg(res.data?.message) || null
                    setmsg(success)
                    closeModal()
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                } else {
                    let err = feedbackMsg(res.data?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(feedbackMsg(err?.response?.data?.error) || null)

                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }

    // mail instructor
    const mailInstructor = (body: any) => {
        startLoading()
        const url = "user/classroom/course/mail"
        axiosPostRequest(url, body)
            .then(res => {
                endLoading()
                if (res.status) {
                    let success = feedbackMsg(res.data?.message) || null
                    setmsg(success)
                    closeModal()
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                } else {
                    let err = feedbackMsg(res.data?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(feedbackMsg(err?.response?.data?.error) || null)

                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }

    // submit assignment
    const submitAssignment = (body: any) => {
        startLoading()
        const url = "user/classroom/course/submit"
        axiosPostRequest(url, body)
            .then(res => {
                endLoading()
                if (res.status) {
                    let success = feedbackMsg(res.data?.message) || null
                    setmsg(success)
                    closeModal()
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                } else {
                    let err = feedbackMsg(res.data?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err?.response?.data?.error)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }

    // profile update
    const profileUpdate = (body: any) => {
        startLoadingBtn()
        const url = "user/update_profile"
        patchFormRequest(url, body)
            .then(res => {
                endLoadingBtn()
                if (res.status) {
                    let success = feedbackMsg(res?.message) || null
                    setmsg(success)
                    getDashboard()
                    closeModal()
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                } else {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoadingBtn()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }
    //user logout
    const logout = () => {
        startLoading();
        const url = "user/signout";
        getRequest(url)
            .then(res => {
                endLoading()
                if (res.status) {
                    localStorage.clear()
                    let success = feedbackMsg(res?.message) || null
                    setmsg(success)
                    window.location.replace(mainpge)

                } else {
                    let err = feedbackMsg(res?.error) || null
                    setmsg(err)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }

            })
            .catch(err => {
                console.log(err)
                endLoading()
                setmsg(err.message)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }

    const completeInstallmentCourseEnrollment = async (id: any) => {
        startLoadingBtn()
        const url = `user/coupon/enroll/${id}/complete`
        await axiosPostRequest(url, "")
            .then(response => {
                endLoadingBtn()
                if (response.status) {
                    setpaymentdata(response?.data?.data)
                    setmsg(feedbackMsg(response?.data?.message) || null)
                    setshowmodal({ id: 100, name: 'payment' })
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }
                else {
                    setmsg(feedbackMsg(response?.data?.error) || null)
                    setTimeout(() => {
                        setmsg(null)
                    }, 10000);
                }
                // history('/home')
            })
            .catch(res => {
                endLoadingBtn()
                setmsg(feedbackMsg(res?.response?.data?.error) || null)
                setTimeout(() => {
                    setmsg(null)
                }, 10000);
            })
    }



    const resetAll = () => {
        // setlandingData(null)
        // setdashboardData(null)
        setcourseData(null)
        setannouncement(null)
        setweekData(null)
        setclassmates(null)
        setgrades(null)
        setsupport(null)
        setprogress(null)

    }

    const value: any = {
        isLoading,
        isLoadingBtn,
        feedback,
        currentUser,
        setCurrentUser,
        showmodal,
        dashboardData,
        toggleSidebar,
        courseData,
        classmates,
        announcement,
        grades,
        support,
        progress,
        weekData,
        landingData,
        msg,
        somethingwrong,
        paymentdata,
        showLoginAlert,
        setShowLoginAlert,
        setmsg,
        closeModal,
        sidebarControl,
        heading,
        startLoading,
        endLoading,
        toggleModal,
        resumeCourse,
        getDashboard,
        getClassmates,
        getAnnouncements,
        getGrades,
        getSupportTeam,
        contactSupport,
        mailInstructor,
        getProgress,
        getWeek,
        submitAssignment,
        logout,
        resetAll,
        setweekData,
        useForceUpdate,
        toggleErrorModal,
        completeInstallmentCourseEnrollment,
        profileUpdate
    }

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    )
}

export default React.memo(Context)


// const baseurl = process.env.REACT_APP_BASEURL
import Cookies from 'universal-cookie';
import axios from 'axios';
const baseurl = "https://api.cholatrek.io/"

const cookies = new Cookies()


let headers = {
    // "Content-Type": "application/json",
    cholatrek_access_token: cookies.get('token'),
};

export const getRequest = async (url: string) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "GET",
        headers: headers,
    })
    return request.json()
}
export const patchRequest = async (url: string, body: any) => {
    const requestOptions = {
        method: "PATCH",
        headers,
        body: JSON.stringify(body)
    }
    const request = await fetch(`${baseurl}${url}`, requestOptions)
    return request.json()
}
export const patchFormRequest = async (url: string, body: any) => {
    const requestOptions = {
        method: "PATCH",
        headers,
        body: body
    }
    const request = await fetch(`${baseurl}${url}`, requestOptions)
    return request.json()
}
export const postRequest = async (url: string, body: any) => {
    const request = await fetch(`${baseurl}${url}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
    })
    return request.json()
}
export const axiosPostRequest = async (url: string, body: any) => {
    const request = await axios.post(`${baseurl}${url}`, body, { headers })

    return request
}


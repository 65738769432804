import React, { useEffect, useState } from 'react'
import { useGlobal } from '../../services/Context'
import { ButtonLoader } from '../../shared/Loaders';
// import ClassroomHeader from '../shared/ClassroomHeader'
// import { LandngSidebar } from '../shared/Sidebar'

function UpdateProfile() {
    const { dashboardData, profileUpdate, isLoadingBtn, getDashboard } = useGlobal()
    const [user, setuser] = useState({
        email: dashboardData?.data?.email,
        lastName: dashboardData?.data?.lastName,
        phone: dashboardData?.data?.phone,
        gender: dashboardData?.data?.gender,
        firstName: dashboardData?.data?.firstName,
        country: dashboardData?.data?.country,
        address: dashboardData?.data?.address,
        linkedin: dashboardData?.data?.linkedin,
        profileImg: dashboardData?.data?.profileImg,
    })

    useEffect(() => {
        setuser({
            email: dashboardData?.data?.email,
            lastName: dashboardData?.data?.lastName,
            phone: dashboardData?.data?.phone,
            gender: dashboardData?.data?.gender,
            firstName: dashboardData?.data?.firstName,
            country: dashboardData?.data?.country,
            address: dashboardData?.data?.address,
            linkedin: dashboardData?.data?.linkedin,
            profileImg: dashboardData?.data?.profileImg,
        })
    }, [dashboardData])
    const handleChange = (e: any) => {
        setuser({
            ...user,
            profileImg: e.target.files[0]
        })
    };
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        var formdata = new FormData();
        formdata.append("email", user.email);
        formdata.append("profileImg", user.profileImg);
        formdata.append("lastName", user.lastName);
        formdata.append("phone", user.phone);
        formdata.append("gender", user.gender);
        formdata.append("country", user.country);
        formdata.append("address", user.address);
        formdata.append("linkedin", user.linkedin);
        formdata.append("firstName", user.firstName);
        await profileUpdate(formdata)
    }
    const handleFieldUpdate = (e: any) => {
        setuser({
            ...user,
            [e.target.name]: e.target.type === "file" ? e.target.files[0] : e.target.value
        })
    }
    return (
        <div>
            <div className="landing" style={{ padding: "1em" }}>
                <div>
                    <form className="auth-group--form" onSubmit={handleSubmit}>
                        <div>
                            <img src={user?.profileImg} alt="img" style={{ borderRadius: "50%" }} />
                            <input type="file" name="profileImg" onChange={handleChange} />
                        </div>
                        {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} style={{width:'100px'}} /> */}
                        <br />
                        <div className="auth-group--pair">
                            <span className="auth-group">
                                <label className="auth-group--label">First Name</label>
                                <input className="auth-group--input" name="firstName" value={user.firstName} onChange={handleFieldUpdate} />
                            </span>
                            <span className="auth-group">
                                <label className="auth-group--label">Last Name</label>
                                <input className="auth-group--input" name="lastName" value={user.lastName} onChange={handleFieldUpdate} />
                            </span>
                        </div>
                        <div className="auth-group--pair">
                            {/* <span className="auth-group">
                                <label className="auth-group--label">Email</label>
                                <input className="auth-group--input" type="email" name="email" value={user.email} onChange={handleFieldUpdate} />
                            </span> */}
                            <span className="auth-group">
                                <label className="auth-group--label">Phone Number</label>
                                <input className="auth-group--input" name="phone" value={user.phone} onChange={handleFieldUpdate} />
                            </span>
                            <span className="auth-group">
                                <label className="auth-group--label">Country</label>
                                <input className="auth-group--input" name="country" value={user.country} onChange={handleFieldUpdate} />
                            </span>
                        </div>

                        <div className="auth-group--pair">
                            <span className="auth-group">
                                <label className="auth-group--label">Address</label>
                                <input className="auth-group--input" name="address" value={user.address} onChange={handleFieldUpdate} />
                            </span>
                            <span className="auth-group">
                                <label className="auth-group--label">LinkedIn</label>
                                <input className="auth-group--input" name="linkedin" value={user.linkedin} onChange={handleFieldUpdate} />
                            </span>
                        </div>
                        <div className="auth-group--pair">

                            <span className="auth-group">
                                <label className="auth-group--label">Gender</label>
                                <select name="gender" value={user.gender} onChange={handleFieldUpdate} className="auth-group--input">
                                    <option>Select Gender</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </span>
                        </div>
                        <button type="submit" className="auth-group--input"
                            style={{
                                backgroundColor: '#0A6DD8',
                                color: "white"
                            }}
                        >{isLoadingBtn ? <ButtonLoader /> : "Save"}</button>

                    </form>
                </div>
            </div>

        </div>
    )
}

export default UpdateProfile

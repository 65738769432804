import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
const OrientationVideo = () => {
    const params: any = useParams()
    const scrollRef: any = useRef(null)
    const { heading, landingData } = useGlobal()
    const [data, setdata]: any = useState(null)

    useEffect(() => {
        landingData?.data?.orientation?.map((item: any) => {
            if (item._id === params.video) {
                setdata(item)
            };
        })
        sectionScroll()
        
    }, [landingData])


    const sectionScroll = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Module</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Orientation</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Video</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <div>
                                <div className="video-description--heading">
                                    <h4>Orientation</h4>
                                    <b>•</b>
                                    <p>Tope Onigbinde</p>
                                </div>
                                <p> Welcome to Cholatrek Training Institute programming courses. </p>
                                <div className="video-description--body">

                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="back-button">
                <Link to={`/expert-led/dashboard/${params.id}/modules/orientation`}>
                    <button> Back</button>
                </Link>
                {/* ← */}
            </div>
            <div>
                <div className="unit-course" >
                    <div className="unit-course--heading">
                        <div>
                            <h4>Video : {data?.title} </h4>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusipiscing elit. Fus.</p> */}
                        </div>
                        <p>Duration : {data?.length}</p>
                    </div>
                    {/* <Card> */}
                    <div className="unit-course--body" style={{ backgroundColor: '#D9E8F9', width: 'fit-content', height: 'fit-content' }}>
                        <div className="unit-course--body--unit" style={{ padding: '0', width: 'fit-content', height: 'fit-content' }}>
                            <div className="video-img" style={{ height: 'fit-content' }} >
                                <video controls controlsList="nodownload" autoPlay>
                                    <source src={data?.video} />
                                            Your browser does not support the video tag.type="video/mp4"
                                </video>
                                {/* <iframe src={`https://www.w3schools.com/html/mov_bbb.mp4`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                            </div>
                        </div>
                    </div>
                    {/* </Card> */}
                </div>
            </div>
        </div>
    )
}

export default OrientationVideo

import React from 'react'
import Pulse from '../assets/images/pulselader.svg'
import Infinity from '../assets/images/Infinity.svg'
import { useGlobal } from '../services/Context'
export const Loaders = () => {
    const { isLoading } = useGlobal()
    return (
        <div style={{ display: isLoading ? 'block' : 'none', position:'fixed', top:'0%', right:'0%',bottom:'0%', left:'0%', backgroundColor:'rgba(0, 0, 0, 0.801)', zIndex:99999999999 }}>
        <span style={{ position:'fixed', top:'50%', right:'50%',transform:'translate(50% , -50%)' }}>
            <img src={Infinity} alt="loading..." />
        </span>
        </div>
    )
}

export const ButtonLoader = () => {
    const { isLoadingBtn } = useGlobal()
    return (
        <span style={{ display: isLoadingBtn ? 'inline-block' : 'none' }}>
            <img src={Pulse} alt="loading..." style={{ width: '40px', height: '40px' }} />
        </span>
    )
}



import React, { ReactElement, ReactHTMLElement, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import { Weeks } from '../../types/course'

type Params = { id: string, week: string }
const Project = () => {
    const params: any = useParams()
    const scrollRef = useRef<HTMLDivElement>(null)
    const { heading, courseData } = useGlobal()

    const project = courseData?.data?.weeks.map((elem: Weeks) => { if (elem.weekId === +params.week) { return elem } })[0]?.project

    useEffect(() => {
        sectionScroll()

    }, [])


    const sectionScroll = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            })
        }
    }

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Module</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Project</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <div>
                                <h4>Project </h4>
                                <p>{project?.title}</p>
                                
                            </div>
                        </Card>
                    </div>
                </div>
            </div><br/><br/>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }} ref={scrollRef}>
                        <Card>
                            <embed src={project?.link} type="application/pdf" height="800" width="100%"></embed>

                        </Card>
                    </div>
                </div>
            </div><br/><br/> 
        </div>
    )
}

export default Project
// "https://www.typescriptlang.org/assets/typescript-handbook.pdf"
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
import Modal from '../../shared/Modal'
import { SupportTeam } from '../../types/support'

type Params = { id: string }
const Support = () => {
    const params: any = useParams()
    const { toggleModal, showmodal, heading, support, getSupportTeam } = useGlobal();

    useEffect(() => {
        if (support === null) {
            getSupportTeam(params.id)
        }
    }, [])
    const supportData = () => {
        const sorted = support?.data?.sort((a: SupportTeam, b: SupportTeam) => a.firstName.localeCompare(b.firstName))
        let prev: string = ""
        // this is done to filter out redundant data (they apear twice)
        let data: SupportTeam[] = []
        sorted?.map((item: SupportTeam) => {
            if (prev !== item?._id) {
                prev = item?._id
                data = [...data, item]
            }
        })
        return data
    }
    const mappedData = supportData()
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Support Team</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con side-image">
                    <div className="reg-progress" style={{ width: "100%", position: "relative" }}>
                        <Card style={{ position: "relative" }}>
                            <div className="left">
                                <h4>Support Team</h4>
                                <p>Reach out to us
                                </p>
                            </div>
                            <div className="right">
                                <img src={IMAGES.Contacts} alt="" style={{ width: "180px", position: "absolute", top: "-8%", right: '1%' }} />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            <div style={{ margin: '3em' }}></div>
            <div>
                <div className="support">
                    <div className="classmate-list">
                        {
                            mappedData?.map((item: SupportTeam) => (
                                <div className="classmate" key={item._id}>
                                    <Card>
                                        <img src={item?.profileImg} alt="" />
                                        <div className="classmate-details">
                                            <h4>{`${item?.firstName} ${item?.lastName}`}</h4>
                                            <span>{item?.stack}</span>
                                            <span>{item?.isTutor && !item.stack  && "Instructor"}</span>
                                            <b>{item?.email}</b>
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="last-module">
                <button onClick={() => toggleModal({ id: 2, name: "contact" })}>Contact Support</button>
                <button onClick={() => toggleModal({ id: 4, name: "mail instructor" })}>Mail Instructor</button>
            </div>
            {showmodal.id === 2 && <ContactModal />}
            {showmodal.id === 4 && <MailInstructorModal data={mappedData} />}
        </div>

    )
}

export default Support

const ContactModal = () => {
    const { contactSupport, closeModal } = useGlobal();
    const [msg, setmsg] = useState("")
    const handleSubmit = (e: any) => {
        e.preventDefault()
        const body = { message: msg }
        contactSupport(body)
        setmsg("")
    }
    const isDisabled = msg === "";

    return (
        <Modal>
            <div className="contact-modal">
                <h4>Contact Support</h4>
                <div>
                    <form>
                        <label>Your Message</label>
                        <textarea placeholder="type something here" rows={7} value={msg} onChange={(e) => setmsg(e.target.value)} />
                        <div className="logout-btn">
                            <button onClick={handleSubmit} disabled={isDisabled}>Send Message</button>
                            <button onClick={closeModal}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

const MailInstructorModal = (props: any) => {
    const params: any = useParams()
    const { mailInstructor, closeModal } = useGlobal();
    const [msg, setmsg] = useState("")
    const [subject, setsubject] = useState("")
    const [instructor, setinstructor] = useState("0")
    const handleSubmit = (e: any) => {
        e.preventDefault()
        const body = {
            courseId: params.id,
            instructorId: instructor,
            subject,
            message: msg
        }
        mailInstructor(body)
        setmsg("")
    }
    const isDisabled = msg === "";
    return (
        <Modal>
            <div className="contact-modal">
                <h4>Mail Instructor</h4>
                <div>
                    <form>
                        <label>Select Instructor</label>
                        <select value={instructor} onChange={(e) => setinstructor(e.target.value)}>
                            <option value="0">Select...</option>
                            {
                                props?.data?.map((item: any, index: any) => (
                                    <Fragment key={index}>
                                        {
                                            item !== undefined &&
                                            <option value={item?._id}>{`${item?.firstName} ${item?.lastName}`}</option>
                                        }
                                    </Fragment>
                                ))
                            }
                        </select>
                        <label>Subject</label>
                        <textarea placeholder="type something here" rows={3} value={subject} onChange={(e) => setsubject(e.target.value)} />
                        <label>Your Message</label>
                        <textarea placeholder="type something here" rows={7} value={msg} onChange={(e) => setmsg(e.target.value)} />
                        <div className="logout-btn">
                            <button onClick={handleSubmit} disabled={isDisabled}>Send Message</button>
                            <button onClick={closeModal}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}
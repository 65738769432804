import Card from './Card'
const Modal = (props: any) => {
    return (
        <div className="modal">

            {
                props.margin ?
                    <div className="no-padding-modal">
                        <Card>{props.children}</Card>
                    </div> :
                    <Card>
                        {props.children}
                    </Card>
            }
        </div>
    )
}

export default Modal

import React from 'react'
import { Link } from 'react-router-dom'
import * as IMAGES from "./images"
const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="footer--part">
                    <img src={IMAGES.DesktopLogo} style={{width:'150px'}} />
                    <p>We make structured and supervised
                    learning possible. Our students have
                    100% career leap when they jump
                    on a high earning skill</p>
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="footer--part">
                    <b>Quick Links</b>
                    <ul>
                        <li><Link to="/courses">Courses</Link></li>
                        <li><Link to="/career">Career</Link></li>
                        <li><Link to="/organization">Organization</Link></li>
                        <li><Link to="/hireinterns">Hire Interns</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                    </ul>
                </div>
                <div className="footer--part">
                    <b>Contact Us</b>
                    <ul>
                        <li>+234 816 050 4976</li>
                        <li>info@cholatrekinstitute</li>
                        <li>House 5 Green Garage, </li>
                        <li>Stateline RD FUTA south gate, </li>
                        <li>Akure, Ondo state Nigeria</li>
                    </ul>
                </div>
                <div className="footer--part">
                    <ul>
                        <li>Terms and Condition</li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
            <div className="footer--foot">
                <b>All right reserver | Cholatrek 2021</b>
                <b style={{float:"right"}}>privacy policy</b>
            </div>
        </footer>
    )
}

export default Footer

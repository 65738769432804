import React, { useEffect } from 'react'
import ClassroomHeader from '../../shared/Header'
import TheContent from '../../shared/TheContent'
import Sidebar from '../../shared/Sidebar'
import { routes } from './routes/routes'
import * as IMAGES from '../../shared/images'
import { Loaders } from '../../shared/Loaders'
import { useGlobal } from '../../services/Context'
import { Outlet, useParams } from 'react-router-dom'
import { Modules } from '../../types/course'

type Params = { id: string, module: string }

const ClassroomLayout = () => {
    const { module, id } = useParams()
    const { toggleSidebar, isLoading, resumeCourse, courseData } = useGlobal()
    const getLogged: any = localStorage.getItem('xu');
    const logged = getLogged || null
    useEffect(() => {
        window.scrollTo({ top: 0 })
        if (courseData === null) {
            resume(id, "crash")
        }
    }, [])

    const resume = (id: any, route: string) => {
        resumeCourse(id, route)
    }

    // filter out the needinfo for each week on the sidebar
    const modules = courseData?.data?.modules?.map((item: Modules) => {
        return {
            courseId: courseData?.data?._id,
            type: "crash",
            title: `Module ${item.moduleId}`,
            hasSubMenu: false,
            subNum: 1,
            link: `modules/module/${item.moduleId}`,
            icon: IMAGES.Vector,
            week: item.moduleId,
            isActive: true,
        }

    }) || []


    const Data = [
        {
            title: 'Dashboard',
            hasSubMenu: false,
            link: `/${logged}`,
            home: true,
            icon: IMAGES.Dashboard,
        },
        {
            title: 'Modules',
            hasSubMenu: true,
            subNum: 1,
            link: 'modules',
            icon: IMAGES.Vector,
            subMenu: [
                // {
                //     title: 'Honour Code',
                //     hasSubMenu: false,
                //     link: 'modules/honour-code',
                //     icon: IMAGES.Vector,
                // },
                // {
                //     title: 'Orientation',
                //     hasSubMenu: false,
                //     link: 'modules/orientation',
                //     icon: IMAGES.Vector,
                // },
                ...modules
            ]
        },
        {
            title: 'Contact Support Team',
            hasSubMenu: false,
            link: 'support',
            icon: IMAGES.Vector5,
        },
        {
            title: 'Progress',
            hasSubMenu: false,
            link: 'progress',
            icon: IMAGES.Vector3,
        },
        {
            title: 'Log Out',
            hasSubMenu: false,
            link: '#',
            icon: IMAGES.Logout,
        }
    ]
    return (
        <div>
            <div>
                <ClassroomHeader />
            </div>
            <div className="main-body">
                {
                    toggleSidebar &&
                    <div id="sidebar">
                        <Sidebar Data={Data} id={id} module={module} path="crash" />
                    </div>
                }

                <div id="main">
                    <main>
                        <Outlet/>
                        {/* <TheContent routes={routes} /> */}
                    </main>
                </div>
                {isLoading && <Loaders />}
            </div>
            <div>
                {/* <Footer /> */}
            </div>
        </div>
    )
}

export default ClassroomLayout

const Progress = (props: any) => {
    const style = {
        display:'inline-block',
        backgroundColor: '#10C87A',
        width: window.innerWidth > 800 ? '35px' : '23px',
        height: '10px'
    }
    let bar: any[]= [];

    for (let index = 0; index < Math.ceil(props.progress/10); index++) {
        bar = [...bar, '']

    }
    return (
        <>
            {
                bar.map((bar: any, index: any) => (
                    <span key={index} style={style} className="progress-bar">
                        {bar}
                    </span>
                ))
            }
        </>

    )
}

export default Progress

import React, { Fragment, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { LandingData } from '../views/landing/_sidebar'
import * as IMAGES from './images'
import { LocationDescriptor, Location } from 'history'
import { useGlobal } from '../services/Context'
const Sidebar = (props: any) => {
    const getLogged: any = localStorage.getItem('xu');
    const logged = getLogged || null
    const [toggleSub, settoggleSub] = useState(false)
    const [toggleInnerSub, settoggleInnerSub] = useState(0)
    const { toggleModal, getWeek, dashboardData, courseData } = useGlobal()
    const location = useLocation()
    const activeStyle = {
        borderLeft: '4px solid #ffffff',
        backgroundColor: '#0050a590'
    }

    const toggleSidebarMenus = () => {
        settoggleSub(current => !current)
    }

    const checkWeekState = () => {
        let weeks = {}
        // checking if a user has a coure id matching the current course id (this is due to the way the data was structured from the backend ...)
        const d = dashboardData?.data?.expert?.find((elem: any) => elem?.courseId?._id === props.id)

        props?.modules?.forEach((menu: any) => {

            //only expert courses are locked
            if (menu?.type !== "expert") weeks = { ...weeks, [`${menu.week}`]: true }
            else if (!d?.cohort) { weeks = { ...weeks, [`${menu.week}`]: true } }
            else {
                // checking if user cohort is in the active cohort array for course
                if (menu?.cohort?.includes(d?.cohort)) { weeks = { ...weeks, [`${menu.week}`]: true } }
                else { weeks = { ...weeks, [`${menu.week}`]: false } }
            }
        })
        return weeks
    }

    const weeksState: any = checkWeekState()

    const toggleSidebarInnerMenu = (id: any, item: any) => {
        if (weeksState[`${item.week}`]) {
            if (id === toggleInnerSub) {
                settoggleInnerSub(0)
            } else {
                settoggleInnerSub(id)
            }
        }
    }
    const handleClick = (week: any, module: any) => {
        const part = {
            courseId: props.id,
            weekId: +week,
            moduleId: +module,
            // lessonId: 1
        }
        let moduleId = location.pathname.charAt(location.pathname.length - 1)
        if (+module !== +moduleId) {
            getWeek(part)
        }
    }
    return (
        <div className="sidebar-con">
            <div className="sidebar">
                {
                    props.Data.map((item: any, index: any) => (
                        <div key={index} className="sidebar--item-con">
                            <div >
                                {
                                    item.hasSubMenu ?
                                        <>
                                            <span className="sidebar--item" onClick={toggleSidebarMenus}>
                                                <img src={item.icon} alt="img" />
                                                <div>
                                                    <span className="sidebar--item-modules">
                                                        <b>{item.title}</b>
                                                        <img src={IMAGES.Drp} alt="drp-dwn" />
                                                    </span>
                                                </div>
                                            </span>
                                            <span className="animate">
                                                {
                                                    toggleSub &&
                                                    item.subMenu?.map((item: any, index: any) => (
                                                        <Fragment key={index}>
                                                            {
                                                                item.hasSubMenu ?
                                                                    <>
                                                                        <span className="sidebar--item" onClick={() => toggleSidebarInnerMenu(item.week, item)}
                                                                            style={{ paddingLeft: '3em ', width: '80% ' }}
                                                                        >
                                                                            <img src={item.icon} alt="img" />
                                                                            <div>
                                                                                <span className="sidebar--item-modules">
                                                                                    <b>{item.title} </b>
                                                                                    {
                                                                                        weeksState?.[`${item.week}`] ?
                                                                                            <img src={IMAGES.Drp} alt="drp-dwn" /> :
                                                                                            <i className="cil-lock-locked" style={{ margin: '0 0 0 3em' }}></i>
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </span>
                                                                        <span className="animate">
                                                                            {
                                                                                toggleInnerSub === item.week &&
                                                                                item?.modules?.map((module: any, index: any) => (
                                                                                    <Fragment key={index}>
                                                                                        <NavLink to={`/${props.path}/dashboard/${props.id}/${module.link}`}
                                                                                            onClick={() => handleClick(item.week, module.moduleId)}
                                                                                            key={index}
                                                                                            style={({ isActive }) => isActive ? { ...activeStyle, paddingLeft: '4em ', width: '80% ' } : { paddingLeft: '4em ', width: '80% ' }}
                                                                                            className="sidebar--item"

                                                                                        >
                                                                                            <img src={module.icon} alt="img" style={{ marginRight: '15px', }} />
                                                                                            <span>
                                                                                                <b>{module.title}</b>
                                                                                            </span>
                                                                                        </NavLink>
                                                                                    </Fragment>
                                                                                ))
                                                                            }
                                                                        </span>
                                                                    </> :

                                                                    <NavLink to={`/${props.path}/dashboard/${props.id}/${item.link}`}
                                                                        key={index}
                                                                        style={({ isActive }) => isActive ? { ...activeStyle, paddingLeft: '3em ', width: '80% ' } : { paddingLeft: '3em ', width: '80% ' }}
                                                                        className="sidebar--item"
                                                                    >
                                                                        <img src={item.icon} alt="img" style={{ marginRight: '15px', }} />
                                                                        <span>
                                                                            <b>{item.title}</b>
                                                                        </span>
                                                                    </NavLink>
                                                            }

                                                        </Fragment>
                                                    ))
                                                }
                                            </span>

                                        </>
                                        :
                                        <>
                                            {
                                                item.link !== "#" ? (
                                                    <NavLink to={`${item.home ? `/` : `/${props.path}/dashboard/${props.id}/${item.link}`}`}
                                                        style={({ isActive }) => isActive ? activeStyle : {}}
                                                        className="sidebar--item"
                                                    >
                                                        <img src={item.icon} alt="img" />
                                                        <span>
                                                            <b>{item.title}</b>
                                                        </span>
                                                    </NavLink>
                                                ) : (
                                                    <Link to="#" className="sidebar--item" onClick={() => toggleModal({ id: 1, name: 'logout' })}>
                                                        <img src={item.icon} alt="img" />
                                                        <span>
                                                            <b>{item.title}</b>
                                                        </span>
                                                    </Link>
                                                )
                                            }
                                        </>

                                }
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Sidebar


export const LandngSidebar = () => {
    const { toggleModal } = useGlobal()

    const activeStyle = {
        borderLeft: '4px solid #ffffff',
        backgroundColor: '#0050a590'
    }

    return (
        <div className="sidebar-con">
            <div className="sidebar">
                {
                    LandingData.map((item, index) => (
                        <div key={index} className="sidebar--item-con">
                            <div >
                                {
                                    item.link !== "#" ? (
                                        <NavLink to={item.link}
                                            style={({ isActive }) => isActive ? activeStyle : {}}
                                            className="sidebar--item">
                                            <img src={item.icon} alt="img" />
                                            <span>
                                                <b>{item.title}</b>
                                            </span>
                                        </NavLink>
                                    ) : (
                                        <Link to="#" className="sidebar--item" onClick={() => toggleModal({ id: 1, name: 'logout' })}>
                                            <img src={item.icon} alt="img" />
                                            <span>
                                                <b>{item.title}</b>
                                            </span>
                                        </Link>
                                    )
                                }
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}
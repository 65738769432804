import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'
const Profile = () => {
    const params: any = useParams()
    const { heading, classmates, getClassmates } = useGlobal()
    const [classmate, setclassmate]: any = useState(null)

    useEffect(() => {
        if (classmates === null) {
            getClassmates(params.id)
        }
        getClassmate()
    }, [classmates])
    const getClassmate = () => {
        classmates?.data?.map((item: any) => {
            if (item._id === params.person) {
                setclassmate(item)
            }
        })
    }
    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Classmates</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Profile</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <Card>
                    <div className="profile-con">
                        <div className="profile--heading">
                            <div>
                                <img src={classmate?.profileImg} alt="img" style={{ borderRadius: "50%", width: "100px", height: "100px" }} />
                            </div>
                            <div>
                                <h4>{`${classmate?.firstName} ${classmate?.lastName}`}</h4>
                            </div>
                        </div>
                        <div className="profie--body">
                            <div className="profile--body--user-details">
                                <div>
                                    <div>
                                        <h4>User Details</h4>
                                    </div>
                                    <div className="profile--body--user-details--main">
                                        <div className="profile--body--user-details--main-col">
                                            <div>
                                                <b>Country</b>
                                                <small>{classmate?.country}.</small>
                                            </div>
                                            <div>
                                                <b>Address</b>
                                                <small>{classmate?.address}.</small>
                                            </div>
                                            <div>
                                                <b>Gender</b>
                                                <small>{classmate?.gender}.</small>
                                            </div>
                                        </div>
                                        <div className="profile--body--user-details--main-col">
                                            <div>
                                                <b>Email</b>
                                                <small><a href={`mailto : ${classmate?.email}`}>{classmate?.email}</a>.</small>
                                            </div>
                                            <div>
                                                <b>Phone Number</b>
                                                <small>{classmate?.phone}.</small>
                                            </div>
                                            <div>
                                                <b>LinkedIn Profile URL</b>
                                                <small><a href={classmate?.linkedin}>{classmate?.linkedin}</a>.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* <div className="profile--body--user-details--main">
                            <div className="profile--body--user-details--main-col" style={{ marginRight: window.innerWidth > 800 ? "17px" : "3px" }}>

                                <div className="profie--body">
                                    <div className="profile--body--user-details">
                                        <div>
                                            <div>
                                                <h4>Course Details</h4>
                                            </div>
                                            <div>
                                                <b>Role</b>
                                                <small>Nigeria.</small>
                                            </div>
                                            <div>
                                                <b>Group</b>
                                                <small>Lagos.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="profile--body--user-details--main-col" style={{ marginLeft: window.innerWidth > 800 ? "17px" : "3px" }}>
                                <div className="profie--body">
                                    <div className="profile--body--user-details">
                                        <div>
                                            <div>
                                                <h4>Administration</h4>
                                            </div>
                                            <div>
                                                <b>Courses</b>
                                                <small>Introduction to css.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </Card>
            </div >
        </div >
    )
}

export default Profile
import React from 'react'
import Footer from '../../shared/Footer'
import ClassroomHeader from '../../shared/Header';
import * as IMAGES from "../../shared/images";
import { useGlobal } from '../../services/Context';

const Page404 = () => {
    const { dashboardData } = useGlobal();

    return (
        <div className="landing" style={{ padding: '0' }}>
            <ClassroomHeader></ClassroomHeader>
            <div className="hero-con">
                <div className="hero">
                    <div className="hero--words" style={{ color: window.innerWidth > 800 ? 'white' : 'black' }}>
                        <h1>Hey {dashboardData?.data?.firstName}, welcome!</h1>
                        <p>We are on this journey together with you, our structured learning curriculum is coupled with a supervised feature. As much as we are sure that our courses are simplified and easy for any kind of student to learn...</p>
                    </div>
                    <div className="hero-img">
                        <img src={IMAGES.Landing} alt="" />
                    </div>
                </div>
            </div>
            <div style={{ width: "50%", margin: "3em auto" }}>
                <img src={IMAGES.Error} alt="" style={{ width: "100%" }} />
            </div>

            {/* <section id="subscribe" style={{ background: "#E5F1FF", marginTop: "5em" }}>
                <div className="subscribe" style={{ background: "#E5F1FF", padding: "5em 0" }}>
                    <div className="subscribe--form">
                        <div className="subscribe--form__word">
                            <h4>Subscribe to our newsletter </h4>
                            <p>Get exclusive discounts and latest news deliverd to your inbox for free!</p>
                        </div>
                        <div className="subscribe--form__input">
                            <input type="email" placeholder="Email" />
                            <button>submit</button>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer></Footer>
        </div>
    )
}

export default Page404

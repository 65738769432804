import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGlobal } from '../../services/Context'
import Card from '../../shared/Card'
import * as IMAGES from '../../shared/images'

const HonourCode = () => {
    const params: any = useParams()
    const { heading, courseData, landingData } = useGlobal();
    const [data, setdata] = useState(null)
    useEffect(() => {
        setdata(courseData)
    })

    return (
        <div>
            <div>
                <Card>
                    <div className="expert-led--tag-con">
                        <div className="expert-led--tag">
                            <h4>{heading(params)} Course</h4>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Module</p>
                            <img src={IMAGES.Drpdown} alt=">" />
                            <p>Honour Code</p>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <div>
                                <h4>Honor Code</h4>
                                <p>Read and accept Cholatrek Training Institute Honour code to receive
                                    access to the Orientation</p>
                                {/* <div className="progress">
                                    <Progress progress={100} />
                                </div>
                                <p>100% complete</p> */}
                                {/* <div className="honour-code--tag">
                                    <p>It is very important to read the Honour Code</p>
                                </div> */}
                                {/* <div className="honour-code--btn"><Link to={`/${params.course}/dashboard/${params.id}/modules/orientation`}><button>Orientation</button></Link></div> */}
                            </div>
                        </Card>
                    </div>
                </div>
            </div><br/><br/>
            <div>
                <div className="reg-progress-con">
                    <div className="reg-progress" style={{ width: "100%" }}>
                        <Card>
                            <embed src={landingData?.data?.honor} type="application/pdf" height="800" width="100%"></embed>

                        </Card>
                    </div>
                </div>
            </div><br/><br/> 
        </div>
    )
}

export default HonourCode
// "https://www.typescriptlang.org/assets/typescript-handbook.pdf"